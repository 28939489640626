import React, { useEffect, useState } from 'react'
import { useUpdatePasswordMutation } from '../../../services/AuthApi';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup'
import { brandLogo } from '../../../assets';


const UpdatePassword = () => {
    // const token = useSelector((state) => state.auth.token);
    const { token } = useParams();
    const [showPassword, setShowPassword] = useState(true)
    const [showConfirmPassword, setShowConfirmPassword] = useState(true)

    const validation = Yup.object().shape({
        rtoken: Yup.string()
            .required('OTP is required'),
        email: Yup.string()
            .email('Wrong email format')
            .min(3, 'Minimum 3 symbols')
            .max(50, 'Maximum 50 symbols')
            .required('Email is required'),
        password: Yup.string()
            .min(6, 'Minimum 8 characters')
            .max(50, 'Maximum 50 characters')
            .required('Password is required'),
        confirmNewPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
    });
    const [updatePassword, {
        data,
        isError,
        isSuccess,
        error,
        isLoading },] = useUpdatePasswordMutation();

    const initialValues = {
        email: "",
        rtoken: '',
        password: "",
        confirmNewPassword: "",

    }
    const formik = useFormik({
        initialValues,
        validationSchema: validation,

        onSubmit: (values) => {
            console.log(values);
            updatePassword({
                email: values.email,
                rtoken: values.rtoken,
                password: values.password,
            });
        },
    });

    const navigate = useNavigate();
    useEffect(() => {
        if (isSuccess) {
            toast.success("Password updated succesfully");
            navigate("/")
        }

        if (isError) {
            if (error) {
                toast.error(error.data.message)
            }
        }
    }, [isSuccess, isError]);
    return (
        <div className="container-fluid sm-bg-login">
            <div className="row login-bg">
                <div className='col-md-1 d-none d-md-block'></div>
                <div className="col-xl-4 col-lg-6 col-md-8">
                    <div className='py-5'>
                        <img src={brandLogo} width={250} alt="" />
                    </div>
                    <div className='white-bg-rounded' style={{marginTop:"70px"}}>
                        <div>
                            <p className="fw-bold text-white text-center">New Password</p>
                            {/* <p className="fw-bold text-white">Enter Your Email Forgot Your Password</p> */}
                        </div>

                        <div>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className="text-start fs-13 text-white fw-bold mt-3">Email</p>
                                        <input type="email" placeholder="Enter Email" className="signupInputs mt-1" {...formik.getFieldProps('email')} />
                                        {formik.touched.email && formik.errors.email && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.email}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-12">
                                        <p for="" className="text-start fs-13 text-white fw-bold mt-3">Enter OTP</p>
                                        <input
                                            type='text'
                                            placeholder='Enter OTP'
                                            className="signupInputs mt-1"
                                            {...formik.getFieldProps('rtoken')}
                                        />
                                        {formik.touched.rtoken && formik.errors.rtoken && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.rtoken}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-12">
                                        <p for="" className="text-start fs-13 text-white fw-bold mt-3">New Password</p>
                                        <input type={showPassword ? "password" : "text"} placeholder="password" className="signupInputs mt-1" {...formik.getFieldProps('password')} />
                                        <div style={{ float: "right" }}>
                                            <i className={`fa ${showPassword ? "fa-eye" : "fa-eye-slash"} position-absolute`}
                                                style={{ marginLeft: "-30px", marginTop: "17px", cursor: "pointer" }}
                                                onClick={() => setShowPassword(!showPassword)}></i>
                                        </div>
                                        {formik.touched.password && formik.errors.password && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.password}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-12">
                                        <p for="" className="text-start fs-13 text-white fw-bold mt-3">Confirm New Password</p>
                                        <input type={showConfirmPassword ? "password" : "text"} placeholder="confirm password" className="signupInputs mt-1" {...formik.getFieldProps('confirmNewPassword')} />
                                        <div style={{ float: "right" }}>
                                            <i className={`fa ${showConfirmPassword ? "fa-eye" : "fa-eye-slash"} position-absolute`}
                                                style={{ marginLeft: "-30px", marginTop: "17px", cursor: "pointer" }}
                                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}></i>
                                        </div>
                                        {formik.touched.confirmNewPassword && formik.errors.confirmNewPassword && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.confirmNewPassword}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-12">
                                        <button className="btn green-btn mt-4 w-100">
                                            {isLoading && <span>Loading...</span>}
                                            {!isLoading && <span>Update Password</span>}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdatePassword