import React from 'react'
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { useDeletePostMutation } from '../../../services/PollApi';

const DeleteModal = ({ show, handleClose, data }) => {


    const [deletePost, { isSuccess, isError, error, isLoading }] = useDeletePostMutation()

    const handleDelete = async (data) => {
        await deletePost(data)
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success("Post Deleted Successfully");
            handleClose()
        }
    }, [isSuccess])
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-md modal-dialog-centered'
                show={show}
                onHide={handleClose}
            >
                <div className='modal-header bg-light'>
                    <h2 className='mb-0 h4'>Delete Confirmation</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        {/* <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' /> */}
                    </div>
                    {/* end::Close */}
                </div>


                <div className='modal-body py-lg-6 px-lg-6'>

                    <p className='fs-6 fw-semibold'>Please confirm that you want to delete <span className='text-danger'>"Post"</span>. <br /> Are you sure you want to delete it?</p>
                </div>
                <div className='modal-footer bg-light'>
                    <button className='btn btn-light' onClick={handleClose}>Cancel</button>
                    <button
                        type='submit'
                        id='kt_sign_up_submit'
                        className='btn btn-danger'
                        onClick={() => handleDelete(data)}
                    >

                        {!isLoading && <span className='indicator-label'>Delete</span>}
                        {isLoading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
            </Modal>
        </>
    )
}

export default DeleteModal