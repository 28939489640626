import { useSelector } from 'react-redux';
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import NavBar from "./components/navbar/NavBar";
import CreatePoll from "./components/Pages/pollingPosts/CreatePoll";
import Global from "./components/Pages/pollingPosts/Global";
import Trending from "./components/Pages/pollingPosts/Trending";
import UpdatePassword from "./components/Pages/auth/UpdatePassword";
import SignUp from './components/Pages/auth/SignUp';
import Login from './components/Pages/auth/Login';
import ForgotPassword from "./components/Pages/auth/ForgotPassword";
import Country from './components/Pages/pollingPosts/Country';

function App() {
  const token = useSelector((state) => state.auth.token);
  return (
    <>
      <Router>
        {/* <NavBar /> */}

        <div className="pages">
          <Routes>
            {token ? (
              // <>
              //   <Route index element={<Navigate to='/global' />} />
              //   <Route path="/global" element={<Global />} />
              //   <Route path="/view-trending" element={<Trending />} />
              //   <Route path="/country-post" element={<Country />} />
              //   <Route path="/create-poll" element={<CreatePoll />} />
              // </>
              <>
                <Route index element={<Navigate to='/global' />} />
                <Route path="/global" element={<><NavBar /><Global /></>} />
                <Route path="/view-trending" element={<><NavBar /><Trending /></>} />
                <Route path="/country-post" element={<><NavBar /><Country /></>} />
                <Route path="/create-poll" element={<><NavBar /><CreatePoll /></>} />
              </>
            ) : (
              <>
                <Route path="/signUp" element={<SignUp />} />
                <Route path="/" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/update-password" element={<UpdatePassword />} />
              </>
            )}
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;
