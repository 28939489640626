import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const postPollApi = createApi({
  reducerPath: 'postPollApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://votingappapiv1.azurewebsites.net/api/PostManagement/',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.userDetail.token
      headers.set('authorization', `Bearer ${token}`)
      return headers;
    },
  }),

  endpoints: (builder) => ({
    postList: builder.query({
      query: ({ pageNumber,pageSize }) => {
        return {
          url: `/GetAllPosts?PageNumber=${pageNumber}&PageSize=${pageSize}`,
          method: "PUT",
          headers: {
            'content-type': ' multipart/form-data',
          },
        }
      },
    }),
    trendingPostList: builder.query({
      query: ({ pageNumber,pageSize }) => {
        return {
          url: `/GetPostsByTrend?PageNumber=${pageNumber}&PageSize=${pageSize}`,
          method: "PUT",
          headers: {
            'content-type': ' multipart/form-data',
          },
        }
      },
    }),
    countryPostList: builder.query({
      query: ({ pageNumber,pageSize }) => {
        return {
          url: `/GetPostsByCountry?PageNumber=${pageNumber}&PageSize=${pageSize}`,
          method: "PUT",
          headers: {
            'content-type': ' multipart/form-data',
          },
        }
      },
    }),
    createPoll: builder.mutation({
      query: (body) => {
        return {
          url: "/CreatePost",
          method: "POST",
          body,
        }
      },
    }),
    deletePost: builder.mutation({
      query: (id) => {
        return {
          url: `/DeletePost?ID=${id}`,
          method: "DELETE",
        }
      },
    }),
    votePoll: builder.mutation({
      query: (body) => {
        return {
          url: "/Vote",
          method: "POST",
          body,
        }
      },
    }),

    postAnalysis: builder.query({
      query: (PostID) => {
        return {
          url: `/PostAnalysis?PostID=${PostID}`,
          method: "PUT",
        }
      },
    }),
    totalLikes: builder.query({
      query: (ID) => {
        return {
          url: `/UpdateLike`,
          method: "PUT",
          params:{ID}
        }
      },
    }),
  }),
})

export const {
  usePostListQuery,
  useTrendingPostListQuery,
  useCountryPostListQuery,
  useCreatePollMutation,
  useDeletePostMutation,
  useVotePollMutation,
  usePostAnalysisQuery,
  useTotalLikesQuery,

} = postPollApi