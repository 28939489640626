
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useRegisterUserMutation } from '../../../services/AuthApi';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { brandLogo, signUpImg } from '../../../assets';
import * as Yup from 'yup'
import { useDispatch } from 'react-redux';
import { setUser } from '../../../features/authSlice';

const SignUp = () => {
  const dispatch = useDispatch()

  const [showPassword, setShowPassword] = useState(true)
  const [confirmShowPassword, setConfirmShowPassword] = useState(true)



  const [registerUser, {
    data,
    isError,
    error,
    isSuccess,
    isLoading },] = useRegisterUserMutation();


  const initialValues = {
    email: "",
    name: "",
    password: "",
    confirmPassword: "",
  }
  const validation = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Name is required'),
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    password: Yup.string()
      .min(6, 'Minimum 8 characters')
      .max(50, 'Maximum 50 characters')
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });
  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: (values) => {
      console.log(values)
      registerUser({ ...values })
    },
  })
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      toast.success("User SignUp Successfully");
      // navigate("/");
      dispatch(setUser(data.data));
      navigate("/global")
    }

    if (isError) {
      if (error && error.response && error.response.status === 400) {
        if (error.response.data && error.response.data.message) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage);
        } else {
          toast.error("An error occurred. Please try again.");
        }
      } else if (error && error.message) {
        toast.error(error.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  }, [isSuccess, isError, error]);
  return (
    <div className="container-fluid sm-bg-login">
      <div className="row login-bg">
        <div className='col-md-1 d-none d-md-block'></div>
        <div className="col-lg-4 col-md-6">
          <div className='py-5'>
            <img src={brandLogo} width={250} alt="" />
          </div>
          <div className='  '>
            <div>
              <h3 className=" text-white pt-5">Join the Image Battle!</h3>
              <p className=" text-white fw-semibold mb-1">Where pictures are worth a thousand votes.</p>
            </div>

            <div className="white-bg-rounded">
              <p className="fw-bold text-white text-center">Sign Up</p>

              {/* <div className="d-flex align-items-center gap-4 justify-content-center">
              <i className="fa fa-facebook-official signup-icons" aria-hidden="true"></i>
              <i className="fa fa-apple signup-icons" aria-hidden="true"></i>
              <i className="fa fa-google signup-icons" aria-hidden="true"></i>
            </div>

            <p className="fw-bold gray text-center mt-4">Or</p> */}

              <form onSubmit={formik.handleSubmit}>
                <input type="text" placeholder="Full Name" className="signupInputs form-control mt-3" {...formik.getFieldProps('name')} />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.name}</span>
                    </div>
                  </div>
                )}
                <input type="email" placeholder="Email" className="signupInputs form-control mt-3" {...formik.getFieldProps('email')} />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.email}</span>
                    </div>
                  </div>
                )}
                <input type={showPassword ? "password" : "text"} placeholder="Password" className="signupInputs form-control mt-3" {...formik.getFieldProps('password')} />
                <div style={{ float: "right" }}>
                  <i className={`fa ${showPassword ? "fa-eye" : "fa-eye-slash"} position-absolute`}
                    style={{ marginLeft: "-30px", marginTop: "-27px", cursor: "pointer" }}
                    onClick={() => setShowPassword(!showPassword)}></i>
                </div>
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.password}</span>
                    </div>
                  </div>
                )}
                <input type={confirmShowPassword ? "password" : "text"}
                  placeholder="Confirm password"
                  className="signupInputs form-control mt-3"
                  {...formik.getFieldProps('confirmPassword')} />
                <div style={{ float: "right" }}>
                  <i className={`fa ${confirmShowPassword ? "fa-eye" : "fa-eye-slash"} position-absolute`}
                    style={{ marginLeft: "-30px", marginTop: "-27px", cursor: "pointer" }}
                    onClick={() => setConfirmShowPassword(!confirmShowPassword)}></i>
                </div>
                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.confirmPassword}</span>
                    </div>
                  </div>
                )}

                {/* <div className="d-flex align-items-center gap-2 mt-3">
                <input type="checkbox" className="" />
                <p className='fs-14 gray fw-bold'>I agree the</p>
              </div> */}
                <button className="btn green-btn mt-4 w-100">
                  {!isLoading && <span>Sign Up</span>}
                  {isLoading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>

                {/* <p className='fs-14 text-white fw-bold text-center mt-3'>Already have an account?
                  <Link to="/">
                    <p className="text-primary text-decoration-none" style={{width:"fit-content"}}>Sign In</p>
                  </Link>
                </p> */}
                <div className='d-flex align-items-center justify-content-center gap-2 mt-3'>
                  <p className='fs-14 text-white fw-bold text-center'>Already have an account?</p>
                  <Link to="/" className='w-auto'>
                    <p className="fs-14 fw-bold text-decoration-none text-center" style={{ color: "#0fa0bf" }}>Sign In</p>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* <div className="col-md-1"></div> */}
        {/* <div className='col-md-6'></div> */}

      </div>
    </div>
  )
}

export default SignUp