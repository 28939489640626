import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCreatePollMutation } from '../../../services/PollApi';
import { useFormik } from 'formik';
import { useForm } from "react-hook-form";
const CreatePoll = () => {
  const [createPoll, { data, isError, error, isSuccess, isLoading }] = useCreatePollMutation();
  const [imagePreviewFirst, setImagePreviewFirst] = useState(null);
  const [imagePreviewSecond, setImagePreviewSecond] = useState(null);
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("postTitle", data.postTitle);
    formData.append("firstImage", data.firstImage[0]);
    formData.append("secondImage", data.secondImage[0]);
    console.log(data)
    createPoll(formData)
  };

  const handleImageChangeFirst = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewFirst(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleImageChangeSecond = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewSecond(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleRemoveImageFirst = () => {
    setImagePreviewFirst(null);
  };

  const handleRemoveImageSecond = () => {
    setImagePreviewSecond(null);
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      toast.success("Poll created Succesfully");
      navigate("/global")
    }

    if (isError) {
      if (error) {
        toast.error(error.data?.message)
      }
    }
  }, [isSuccess, isError]);
  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-center mt-5">
          <div className="col-md-6">
            <div className='white-bg-rounded'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-md-12">
                    <h3 className='text-white text-center'>
                    Create Your Own Poll
                    </h3>
                  </div>
                  <div className="col-md-12">
                    <p className="text-start fs-13 text-white fw-bold mt-3">Title</p>
                    <input type="text" placeholder="Enter a Title" className="signupInputs mt-1" {...register("postTitle")} />
                  </div>
                  <div className="col-md-6">
                    <p className="text-start fs-13 text-white fw-bold mt-3">Image-A</p>
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                      {/* {!imagePreviewFirst && ( */}
                        <div className='border-dashed-1px w-75 mt-2'>
                          <input type="file" className="uploadPhoto cursor-pointer" {...register("firstImage")} onChange={handleImageChangeFirst} />
                          <p className='fs-12 fw-14 text-white position-absolute'>Upload Image</p>
                        </div>
                      {/* )} */}
                      <div className='w-75'>
                        {imagePreviewFirst && (
                          <>
                            <button type='button' className='btn btn-danger btn-sm position-absolute mt-3 ms-2' onClick={handleRemoveImageFirst}>
                              <i class="fa fa-trash-o"></i>
                            </button>
                            <img src={imagePreviewFirst} alt="Image Preview" className="mt-2 br-10 " style={{ width: '100%', height: '300px' }} />

                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <p className="text-start fs-13 text-white fw-bold mt-3">Image-B</p>
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                      {/* {!imagePreviewSecond && ( */}
                        <div className='border-dashed-1px w-75 mt-2'>
                          <input type="file" className="uploadPhoto cursor-pointer" {...register("secondImage")} onChange={handleImageChangeSecond} />
                          <p className='fs-12 fw-14 text-white position-absolute'>Upload Image</p>
                        </div>
                      {/* )} */}
                      <div className='w-75'>
                        {imagePreviewSecond && (
                          <>
                            <button type='button' className='btn btn-danger btn-sm position-absolute mt-3 ms-2' onClick={handleRemoveImageSecond}>
                              <i class="fa fa-trash-o"></i>
                            </button>
                            <img src={imagePreviewSecond} alt="Image Preview" className="mt-2 br-10" style={{ width: '100%', height: '300px' }} />
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 d-flex mt-5">
                    <button className="btn green-btn mt-4 ps-5 pe-5 ms-auto">
                      {!isLoading && <span>Add</span>}
                      {isLoading && <span>Please wait...</span>}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default CreatePoll