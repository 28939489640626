import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useSingleUserDetailQuery, useUpdateUserMutation } from '../../../services/AuthApi';
import ReactFlagsSelect from "react-flags-select";
import { useSelector } from 'react-redux';
import axios from 'axios';
import ProfileModal from './ProfileModal';


const ProfileDetail = (props) => {

    const userId = useSelector((state) => state.auth.id);

    const { data,refetch, } = useSingleUserDetailQuery(userId);

    const [showUpdateUserModal, setShowUpdateUserModal] = useState(false)
    const [editData, setEditData] = useState({});
    const editUserHandler = () => {
        setShowUpdateUserModal((prev) => !prev);
    };
    useEffect(() => {
     if (editUserHandler) {
        refetch()
     }
    }, [])
    
    return (
        <>
            <ProfileModal show={showUpdateUserModal}
                handleClose={() => setShowUpdateUserModal(false)}
                data={editData} />
            <Modal show={props.show} onHide={props.close}>
                <Modal.Header closeButton>
                    <Modal.Title>Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <input type="text" value={userId}/> */}
                    <div className="row">
                        <div className="col-md-4">
                            <div className='border' style={{ height: "120px" }}></div>
                        </div>
                        <div className="col-md-8">
                            <div className='d-flex align-items-start justify-content-end h-100'>
                                <button className='btn btn-primary'
                                    onClick={() => {
                                        setEditData(data);
                                        editUserHandler();
                                    }}>Edit Prfile</button>
                            </div>

                        </div>

                        <div className="col-md-12">
                            <div className='mt-3'>
                                <label htmlFor="" className='fs-14 fw-bold gray mb-2'>Name:</label>
                                <p className='fs-14 '>{data?.data?.name}</p>
                            </div>
                            <div className='mt-3'>
                                <label htmlFor="" className='fs-14 fw-bold gray mb-2'>Email</label>
                                <p className='fs-14 '>{data?.data?.email}</p>
                            </div>
                            <div className='mt-3'>
                                <label htmlFor="" className='fs-14 fw-bold gray mb-2'>Country</label>
                                <p className='fs-14 '>{data?.data?.country}</p>
                            </div>
                            <div className='mt-3'>
                                <label htmlFor="" className='fs-14 fw-bold gray mb-2'>Gender</label>
                                <p className='fs-14 '>{data?.data?.gender}</p>
                            </div>
                            <div className='mt-3'>
                                <label htmlFor="" className='fs-14 fw-bold gray mb-2'>Age</label>
                                <p className='fs-14 '>{data?.data?.age}</p>
                            </div>
                        </div>
                    </div>

                </Modal.Body>   
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.close}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ProfileDetail