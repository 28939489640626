import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://votingappapiv1.azurewebsites.net/api/Account/' }),
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: (body) => {
        return {
          url: "/Login",
          method: "POST",
          body,
        }
      },
    }),
    registerUser: builder.mutation({
      query: (body) => {
        return {
          url: "/Register",
          method: "POST",
          body,
        }
      },
    }),
    forgotPassword: builder.mutation({
      query: (body) => {
        return {
          url: "/ForgetPassword",
          method: "POST",
          body,
        }
      },
    }),
    updatePassword: builder.mutation({
      query: ({rtoken,password,email}) => {
        return {
          url: `/UpdatePassword?rtoken=${rtoken}&password=${password}&email=${email}`,
          method: "POST",
        }
      },
    }),
    updateUser: builder.mutation({
      query: (body) => {
        return {
          url: "/AddUpdateUser",
          method: "PUT",
          body,
        }
      },
    }),
    singleUserDetail: builder.query({
      query: ( userId ) => {
        return {
          url: `/GetUserProfile`,
          method: "PUT",
         params:{userId}
        }
      },
    }),
  }),
})


export const {
  useLoginUserMutation,
  useRegisterUserMutation,
  useForgotPasswordMutation,
  useUpdatePasswordMutation,
  useUpdateUserMutation,
  useSingleUserDetailQuery
} = authApi