import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useCountryPostListQuery, useDeletePostMutation, usePostListQuery, useVotePollMutation } from '../../../services/PollApi';
// import { format } from 'date-fns'
import { Dropdown } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import InfiniteScroll from 'react-infinite-scroll-component';
import ModalImage from "react-modal-image";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import DeleteModal from './DeleteModal';
import PostAnalysisModal from './PostAnalysisModal';
import { logout } from '../../../features/authSlice';
import { dummyProfile } from '../../../assets';

const Country = () => {

  const userId = useSelector((state) => state.auth.id);


  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [allPosts, setAllPosts] = useState([]);

  const [hasMore, setHasMore] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  console.log(allPosts)
  const { data: postData,
    isError: postIsError,
    error: postError,
    isSuccess: postIsSuccess,
    isLoading: postIsLoading,
    refetch } = useCountryPostListQuery({ pageNumber, pageSize }, { keepPreviousData: true });
  console.log("postData", postData)
  const [deletePost] = useDeletePostMutation()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  }
  useEffect(() => {
    if (postIsError) {
      if (postError) {
        if (postError.status === 401) {
          handleLogout();
        } else {
          toast.error(postError?.data?.message);
        }
      }
    }
  }, [postError, postIsError]);

  const [votePoll, { data: voteData, isLoading: pollIsLoading }] = useVotePollMutation()

  const [likedPosts, setLikedPosts] = useState({});
  // Load liked posts from local storage on initial render
  useEffect(() => {
    const storedLikedPosts = localStorage.getItem('likedPosts');
    if (storedLikedPosts) {
      setLikedPosts(JSON.parse(storedLikedPosts));
    }
  }, []);

  // Save liked posts to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem('likedPosts', JSON.stringify(likedPosts));
  }, [likedPosts]);
  const handleVoteEvent = (postId, imageId) => {
    // Check if the post is already liked
    if (likedPosts[postId]?.includes(imageId)) {
      // Unlike the post
      const updatedLikedPosts = { ...likedPosts };
      updatedLikedPosts[postId] = updatedLikedPosts[postId]?.filter(id => id !== imageId);
      setLikedPosts(updatedLikedPosts);
      // Make API call to unlike the image
      votePoll({ postId, postImageId: imageId, status: false });
      // refetch()
    } else {
      // Like the post and remove like from other images of the same post
      const updatedLikedPosts = {
        ...likedPosts,
        [postId]: [imageId],
      };
      setLikedPosts(updatedLikedPosts);

      // Make API call to vote for the image
      votePoll({ postId, postImageId: imageId, status: true });
      // refetch()

    }
  };

  // ---------------------

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure that you wanted to delete that Post?")) {
      toast.success("Post Deleted Successfully");
    }
    await deletePost(id)
    refetch();
  };

  // -------------------------------

  const loadMorePosts = () => {
    if (postIsLoading || loading) {
      return;
    }
    setLoading(true);
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  useEffect(() => {
    if (pageNumber > 1) {
      refetch({ pageNumber }).then((response) => {
        const newData = response?.data?.data;
        if (newData && newData.length > 0) {
          setAllPosts((prevPosts) => [...prevPosts, ...newData]);
        } else {
          setHasMore(false);
        }
        setLoading(false); // Set loading state to false after fetching data
      });
    }
  }, [pageNumber, refetch]);

  useEffect(() => {
    if (postIsSuccess && pageNumber === 1) {
      setAllPosts(postData.data);
    }
  }, [postIsSuccess, pageNumber, postData]);



  const [showPostAnalysisModal, setShowPostAnalysisModal] = useState(false)
  const [postAnalysisData, setPostAnalysisData] = useState({});
  const postAnalysisHandler = () => {
    setShowPostAnalysisModal((prev) => !prev);
  };
  const [showPostDeleteModal, setShowPostDeleteModal] = useState(false)
  const [postDeleteData, setPostDeleteData] = useState({});
  const deleteHandler = () => {
    setShowPostDeleteModal((prev) => !prev);
  };
  useEffect(() => {
    if (showPostDeleteModal == false || showPostAnalysisModal == false) {
      refetch();
    }
  }, [showPostDeleteModal, showPostAnalysisModal])


  const formatDate = (date) => {
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'day').startOf('day');
    const postDate = moment(date);

    if (postDate.isSame(today, 'd')) {
      return 'Today ' + postDate.format('hh:mm a');
    } else if (postDate.isSame(yesterday, 'd')) {
      return 'Yesterday ' + postDate.format('hh:mm a');
    } else {
      return postDate.format('MMM DD, YYYY hh:mm a');
    }
  };

  return (
    <>
      <PostAnalysisModal show={showPostAnalysisModal}
        handleClose={() => setShowPostAnalysisModal(false)}
        data={postAnalysisData} />
      <DeleteModal show={showPostDeleteModal} handleClose={() => setShowPostDeleteModal(false)} data={postDeleteData} />

      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-md-12">
            <div className="row justify-content-center">
              <div className="col-md-6 col-lg-6 col-xl-5 mb-3">
                <div className='white-bg-rounded bg-light'>
                  <div className='d-flex align-items-center gap-2'>
                    <Link to="/create-poll" className='w-100 text-decoration-none'>
                      <div className='d-flex gap-2 align-items-center'>
                        <i class="fa fa-plus-circle fa-2x bounce"></i>
                        <input type="text" placeholder='Create your poll' className='border index-inoutField' />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {postIsLoading && <div className='d-flex align-items-center justify-content-center mt-4'>
            <Spinner animation="grow" variant="info" />
          </div>}
          <InfiniteScroll
            dataLength={allPosts ? allPosts.length : 0} // Total number of items
            next={loadMorePosts} // Function to load more items
            hasMore={!postIsLoading && !loading} // Whether there are more items to load
          // loader={<Spinner animation="grow" variant="info" />} // Loading indicator
          // endMessage={
          //   allPosts && allPosts.length === 0 ? (
          //     <p className='text-center text-white'>No posts available</p>
          //   ) : (
          //     null
          //   )
          // } 
          >
            {allPosts?.length > 0 ?
              <>
                {allPosts?.map((data, index) => (
                  <div className="col-md-12 mb-3" key={data.id}>
                    <div className="row justify-content-center">
                      <div className="col-md-6 col-lg-6 col-xl-5">
                        <div className='white-bg-rounded'>
                          <div className='d-flex align-items-center justify-content-between'>
                            <div className='d-flex align-items-center gap-2  pb-2'>
                              <img src={data?.user?.imagePath == "" || data?.user?.imagePath == null ? dummyProfile : data?.user?.imagePath} className='rounded-circle' alt="" height={40} width={40} />
                              <div>
                                <p className='fw-bold text-white fs-13'>{data?.user?.name}</p>
                                <p className='fs-12 text-white'>{formatDate(data.createdOn)}</p>

                              </div>
                            </div>
                            {data.userID === userId &&
                              <Dropdown>
                                <Dropdown.Toggle variant="transparent" id="dropdown-basic" className='removeArrow'>
                                  <i class="fa fa-ellipsis-v text-white" aria-hidden="true"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => {
                                    setPostAnalysisData(data.id);
                                    postAnalysisHandler();
                                  }}>Analysis</Dropdown.Item>
                                  <Dropdown.Item onClick={() => {
                                    setPostDeleteData(data?.id);
                                    deleteHandler();
                                  }}>Delete</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>}
                          </div>
                          <div className='row  br-10'>
                            <div className='col-md-12 mb-2'>
                              <p className='fs-14 text-white'>{data.title}</p>
                            </div>
                            {data?.postImage?.map((imageData, index) => (
                              <div className='col-6'>
                                <ModalImage
                                  small={imageData.imagePath}
                                  large={imageData.imagePath}
                                  hideDownload={true}
                                  className="br-10 imagesPreview border"
                                />
                                <div className='d-flex align-items-center gap-1 mt-2'>
                                  <div className='bg-danger rounded-circle ms-2' style={{ width: "20px", height: "20px" }}>
                                    <i className='fa fa-heart text-white fs-10  d-flex align-items-center justify-content-center position-relative' style={{ top: "6px" }}></i>
                                  </div>
                                  <p className='ms-1 fs-14 text-white'>{imageData?.likeStatus == true ? (imageData.likes - 1) + (likedPosts[data.id]?.filter(id => id === imageData.id).length || 0) : (imageData.likes) + (likedPosts[data.id]?.filter(id => id === imageData.id).length || 0)}</p>
                                </div>

                                <div className='border-top border-bottom pt-1 pb-1 mt-2'>
                                  <div onClick={() => handleVoteEvent(data.id, imageData.id)}>
                                    {likedPosts[data.id]?.includes(imageData.id) ? (
                                      <p className={`fs-14 fw-bold cursor-pointer ms-2 btn text-danger`}>
                                        <i className='fa fa-heart me-2'></i>
                                        Love
                                      </p>
                                    ) : (
                                      <p className={` fs-14 fw-bold cursor-pointer ms-2 btn text-white`}>
                                        <i className='fa fa-heart me-2'></i>
                                        Love
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
              : null}
          </InfiniteScroll>
        </div>
      </div>
    </>
  )
}

export default Country