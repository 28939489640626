import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

const persistConfig = {
    key: "authSlice",
    version: 1,
    storage,
    blacklist: [],
}
const initialState = {
    isLoggedIn: false,
    userDetail: null,
    token: '',
    id: '',


}

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUser: (state, data) => {
            state.isLoggedIn = true;
            state.userDetail = data.payload;
            state.token = data.payload.token;
            state.name = data.payload.name;
            state.email = data.payload.email;
            state.id = data.payload.id;



        },
        logout: (state) => {
            state.isLoggedIn = false;
            state.userDetail = null;
            state.token = null;
            state.name = null;
            state.email = null;
            state.id = null;


        }
    }
})

export const { setUser, logout } = authSlice.actions;

export default persistReducer(persistConfig, authSlice.reducer);

