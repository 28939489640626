import { useFormik } from 'formik';
import React, { useEffect } from 'react'
import { useForgotPasswordMutation } from '../../../services/AuthApi';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { brandLogo } from '../../../assets';

const ForgotPassword = () => {
    const [forgotPassword, {
        data,
        isError,
        isSuccess,
        error,
        isLoading },] = useForgotPasswordMutation();


    const initialValues = {
        email: "",
    }
    const formik = useFormik({
        initialValues,
        // validationSchema: loginSchema,
        onSubmit: (values) => {
            console.log(values)
            forgotPassword({ ...values })
        },
    })
    const navigate = useNavigate();

    useEffect(() => {
        if (isSuccess) {
            toast.success("OTP send check your Email");
            navigate("/update-password")
        }

        if (isError) {
            if (error) {
                toast.error(error.data.message)
            }
        }
    }, [isSuccess, isError]);
    return (
        <div className="container-fluid sm-bg-login">
            <div className="row login-bg">
                <div className='col-md-1 d-none d-md-block'></div>
                <div className="col-lg-4 col-md-6">
                    <div className='py-5'>
                        <img src={brandLogo} width={250} alt="" />
                    </div>
                    <div className='white-bg-rounded' style={{marginTop:"150px"}}>
                        <div>
                            <p className="fw-bold text-white text-center">Forgot Password</p>
                            {/* <p className="fw-bold text-white">Enter Your Email</p> */}
                        </div>

                        <div>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p for="" className="text-start fs-13 text-white fw-bold mt-3">Email</p>
                                        <input type="text" placeholder="Email" className="signupInputs mt-1" {...formik.getFieldProps('email')} />
                                    </div>
                                    <div className="col-md-12">
                                        <button className="btn green-btn mt-4 w-100"> {isLoading && <span>Loading...</span>}{!isLoading && <span>Reset Passowrd</span>}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword