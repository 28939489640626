import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useSingleUserDetailQuery, useUpdateUserMutation } from '../../../services/AuthApi';
import ReactFlagsSelect from "react-flags-select";
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup'
import { dummyProfile } from '../../../assets';




const ProfileModal = ({ show, close, data, handleClose }) => {

    const userId = useSelector((state) => state.auth.id);
    const { data: userDetail, refetch, } = useSingleUserDetailQuery(userId);
    const [selected, setSelected] = useState("");
    const [editMode, setEditMode] = useState(false);
    const [userUpdate, { isSuccess, isError, isLoading }] = useUpdateUserMutation();
    const [id, setId] = useState(0);
    const [profileImageFile, setProfileImageFile] = useState(null);
    const validation = Yup.object().shape({
        email: Yup.string()
            .email('Wrong email format')
            .min(3, 'Minimum 3 symbols')
            .max(50, 'Maximum 50 symbols')
            .required('Email is required'),
        country: Yup.string()
            .required('Country is required'),
        gender: Yup.string()
            .required('Gender is required'),
        age: Yup.string()
            .required('Age is required'),
    })
    const initialValues = {
        userId: '',
        age: 0,
        name: "",
        email: "",
        country: "",
        gender: "",
        profileimage: "",
        imageUrl: ""
    }

    useEffect(() => {
        if (data) {
            setId(data?.data?.id);
            // formik.setValues({ ...data?.data })
            formik.setValues({
                ...initialValues,
                userId: data?.data?.id,
                name: data?.data?.name,
                email: data?.data?.email,
                country: data?.data?.country,
                gender: data?.data?.gender,
                age: data?.data?.age,
                profileimage: data?.data?.imagePath,
                imageUrl: data?.data?.imagePath,
            });
            // Set the image preview if profileimage is not empty
            if (data?.data?.imagePath) {
                setImagePreviewFirst(data?.data?.imagePath);
            }
        }
    }, [data]);
    console.log("id", id)
    const formik = useFormik({
        initialValues,
        validationSchema: validation,
        onSubmit: (values) => {
            console.log(values)
            // userUpdate({ ...values })
            const formData = new FormData();
            formData.append('userId', values.userId);
            formData.append('name', values.name);
            formData.append('email', values.email);
            formData.append('gender', values.gender);
            formData.append('age', values.age);
            formData.append('country', values.country);
            // formData.append('profileimage', values.profileimage);
            // Append the profile image file if it is not empty
            if (profileImageFile) {
                formData.append('profileimage', profileImageFile);
            }

            userUpdate(formData);
        },
    })

    const handleCountryChange = (code) => {
        formik.setFieldValue('country', code); // Set the formik field value
        setSelected(code); // Update the selected state
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success('User Updated Successfully');
            handleClose();
            setEditMode(false)
        }
    }, [isSuccess]);
    useEffect(() => {
        if (isError) {
            toast.error('Something went wrong')
        }
    }, [isError])


    const enableEditMode = () => {
        setEditMode(true);
    };
    const resetEditMode = () => {
        setEditMode(false);
    };
    const [imagePreviewFirst, setImagePreviewFirst] = useState(null);

    // const handleImageChangeFirst = (e) => {
    //     const file = e.target.files[0];
    //     if (file) {
    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             setImagePreviewFirst(reader.result);
    //             formik.setFieldValue('profileimage', file);
    //         };
    //         reader.readAsDataURL(file);
    //     }
    // };
    const handleImageChangeFirst = (e) => {
        const file = e.target.files[0];
    
        // Set the image preview
        const reader = new FileReader();
        reader.onload = () => {
          setImagePreviewFirst(reader.result);
        };
        reader.readAsDataURL(file);
    
        // Set the image file
        setProfileImageFile(file);
      };

    return (
        <Modal show={show} onHide={() => {
            handleClose();
            resetEditMode();
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Profile</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    {/* <input type='hidden'
                        {...formik.getFieldProps('userId')}
                    /> */}
                    <div className="row">
                        <div className="col-md-9">
                            <div className='d-flex align-items-center gap-2'>
                                <div className='' style={{ height: "120px", width: "150px" }}>

                                    {editMode ?
                                        <img src={imagePreviewFirst ? imagePreviewFirst : dummyProfile} width="120px" height="120px" alt="" />
                                        :
                                        <img src={userDetail?.data?.imagePath == "" ? dummyProfile : userDetail?.data?.imagePath} width="120px" height="120px" />
                                    }
                                </div>
                                {editMode &&
                                    <div>
                                        <input type="file" className='position-absolute'
                                            style={{ width: "123px", opacity: "0" }}
                                            id="profileimage"
                                            name="profileimage"
                                            accept=".png, .jpg, .jpeg"
                                            // {...formik.getFieldProps('profileimage')}
                                            onChange={handleImageChangeFirst}
                                        />
                                        <button className='btn btn-primary'>Upload Image</button>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='col-md-3 '>
                            <div className='d-flex'>
                                <p className='btn btn-secondary ms-auto ' onClick={enableEditMode}> <i className='fa fa-edit'></i> </p>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className='mt-3'>
                                <label htmlFor="" className='fs-14 fw-bold gray mb-2'>Name</label>
                                <input type="text"
                                    className='form-control'
                                    placeholder='Enter Name'
                                    {...formik.getFieldProps('name')}
                                    disabled={!editMode}
                                />
                            </div>
                            <div className='mt-3'>
                                <label htmlFor="" className='fs-14 fw-bold gray mb-2'>Email</label>
                                <input type="email"
                                    className='form-control'
                                    placeholder='Enter Email'
                                    {...formik.getFieldProps('email')}
                                    disabled={!editMode} />
                                {formik.touched.email && formik.errors.email && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.email}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='mt-3'>
                                <label htmlFor="" className='fs-14 fw-bold gray mb-2'>Country</label>
                                <ReactFlagsSelect
                                    // selected={selected}
                                    onSelect={handleCountryChange}
                                    disabled={!editMode}
                                    showSelectedLabel={true}
                                    searchable={true}
                                    selected={formik.values.country}
                                />
                                {formik.touched.country && formik.errors.country && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.country}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className='mt-3'>
                                <label htmlFor="" className='fs-14 fw-bold gray mb-2'>Gender</label>
                                <select name="" id="" className="form-select "   {...formik.getFieldProps('gender')} disabled={!editMode}>
                                    <option value="">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                </select>
                                {formik.touched.gender && formik.errors.gender && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.gender}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className='mt-3'>
                                <label htmlFor="" className='fs-14 fw-bold gray mb-2'>Age</label>
                                <input type="number"
                                    className='form-control'
                                    placeholder='Enter age'
                                    {...formik.getFieldProps('age')}
                                    disabled={!editMode} />
                                {formik.touched.age && formik.errors.age && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.age}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        handleClose();
                        resetEditMode();
                    }}>
                        Close
                    </Button>
                    {editMode &&
                        <Button variant="primary" type='submit'
                        // onClick={() => {
                        //     formik.handleSubmit();
                        //     resetEditMode();
                        // }}
                        >
                            {!isLoading && <span>Save</span>}
                            {isLoading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </Button>}
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default ProfileModal