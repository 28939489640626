import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { brandLogo, signInImg } from '../../../assets';
import { setUser } from '../../../features/authSlice';
import { useLoginUserMutation } from '../../../services/AuthApi';
import * as Yup from 'yup'

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(true)

  const initialValues = {
    email: "",
    password: "",
  }

  const [loginUser, {
    data,
    isError,
    error,
    isSuccess,
    isLoading },] = useLoginUserMutation();


  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    password: Yup.string()
      .min(6, 'Minimum 8 characters')
      .max(50, 'Maximum 50 characters')
      .required('Password is required'),
  });
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      console.log(values)
      loginUser({ ...values })
    },
  })

  useEffect(() => {
    if (isSuccess) {
      toast.success("User Login Succesfully");
      dispatch(setUser(data.data));
      navigate("/global")
    }
  }, [isSuccess]);

  useEffect(() => {
    // if (isError) {
    //   if (error && error.data && error.data.errors) {
    //     const apiErrors = error.data.errors;
    //     const errorKeys = Object.keys(apiErrors);
    //     let delay = 0;

    //     errorKeys.forEach((field) => {
    //       const fieldErrors = apiErrors[field];
    //       fieldErrors.forEach((errorMessage) => {
    //         setTimeout(() => {
    //           toast.error(errorMessage);
    //         }, delay);
    //         delay += 2000000; // Delay between each toast in milliseconds
    //       });
    //     });
    //   } else if (error && error.message) {
    //     toast.error(error.message);
    //   }
    // }
    if (isError) {
      toast.error("Email & Password incorrect..!");
    }
  }, [isError, error]);
  return (
    <div className="container-fluid sm-bg-login">
      <div className="row login-bg">
        <div className='col-md-1 d-none d-md-block'></div>
        <div className="col-lg-4 col-md-6">
          <div className='py-5'>
            <img src={brandLogo} width={250} alt="" />
          </div>
          <div className='mt-5'>
            <div>
              <h3 class=" text-white">Vote for Your Favorites!</h3>
              <p class="text-white fw-semibold mb-1">Your Vote Decides the Next Trending Picture.</p>
            </div>
            <div className='white-bg-rounded'>
              <div>
                <p className="fw-bold text-white text-center">LogIn</p>
                {/* <p className="fw-bold text-white">Enter Your Email and Password for sign In</p> */}
              </div>
              <div>
                <form onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-md-12">
                      <p className="text-start fs-13 text-white fw-bold mt-3">Email</p>
                      <input type="text" placeholder="Email" className="signupInputs form-control mt-1" {...formik.getFieldProps('email')} />
                      {formik.touched.email && formik.errors.email && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.email}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-md-12">
                      <p className="text-start fs-13 text-white fw-bold mt-3">Password</p>
                      <input type={showPassword ? "password" : "text"} placeholder="Password" className="signupInputs form-control mt-1" {...formik.getFieldProps('password')} />
                      <div style={{ float: "right" }}>
                        <i className={`fa ${showPassword ? "fa-eye" : "fa-eye-slash"} position-absolute`}
                          style={{ marginLeft: "-30px", marginTop: "-27px", cursor: "pointer" }}
                          onClick={() => setShowPassword(!showPassword)}></i>
                      </div>
                      {formik.touched.password && formik.errors.password && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.password}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-md-12 d-flex">
                      <Link to="/forgot-password" className='text-decoration-none ms-auto' style={{ width: "fit-content" }}>
                        <p className="fw-bold mt-2" style={{ color: "#0fa0bf" }}>Forgot Password</p>
                      </Link>
                    </div>
                    <div className="col-md-12">

                      <button className="btn green-btn mt-4 w-100">
                        {!isLoading && <span>Sign In</span>}
                        {isLoading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>


                    </div>
                    <div className="col-md-12">
                      <div className='d-flex align-items-center justify-content-center gap-2 mt-3'>
                        <p className='fs-14 text-white fw-bold text-center'>Don't have an account?</p>
                        <Link to="/signUp" className='w-auto' >
                          <p className="fs-14 fw-bold text-decoration-none text-center" style={{ color: "#0fa0bf" }}>Sign Up</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='col-md-1'></div>
        <div className='col-md-6'></div> */}
      </div>
    </div>
  )
}

export default Login