import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { brandLogo, dummyProfile, navLogoImg } from "../../assets";
import { logout } from "../../features/authSlice";
import ProfileModal from "../Pages/profile/ProfileModal";
import "./NavBar.css";
import ProfileDetail from "../Pages/profile/ProfileDetail";
import { useEffect } from "react";
import { useSingleUserDetailQuery } from "../../services/AuthApi";

function NavBar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
    toast.success("User Logout Successfull")
    navigate("/");
  }
  const token = useSelector((state) => state.auth.token);
  // const name = useSelector((state) => state.auth.name);
  // const email = useSelector((state) => state.auth.email);



  const [showLogin, setShowLogin] = useState(false);



  const userId = useSelector((state) => state.auth.id);

  const { data: userDetail, refetch, } = useSingleUserDetailQuery(userId);
  console.log("userDetail", userDetail);

  const [showUpdateUserModal, setShowUpdateUserModal] = useState(false)
  const [editData, setEditData] = useState({});
  const editUserHandler = () => {
    setShowUpdateUserModal((prev) => !prev);
  };
  useEffect(() => {
    if (showUpdateUserModal === false) {
      refetch()
    }
  }, [showUpdateUserModal])


  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    if (dropdownOpen) {
      refetch();
    }
  }, [dropdownOpen]);

  return (
    <>
      <ProfileDetail show={showLogin} close={() => setShowLogin(false)} />
      <ProfileModal show={showUpdateUserModal}
        handleClose={() => setShowUpdateUserModal(false)}
        data={editData} />
      <nav className="navbar">
        <div className="container">
          <Link to="/global" className="text-decoration-none">
            <h2 className="nav-logo mb-0 d-flex">
              <img src={brandLogo} width={250} alt="" />
              {/* Poll Battle */}
            </h2>
          </Link>
          <ul className={click ? "nav-menu active m-lg-0" : "nav-menu"}>
            {token ? (
              <>
                <li className="nav-item">
                  <NavLink
                    to="/global"
                    activeClassName="active"
                    className="nav-links"
                    onClick={handleClick}
                  >
                    Poll World
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/view-trending"
                    activeClassName="active"
                    className="nav-links"
                    onClick={handleClick}
                  >
                    Crowd Favorites
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/country-post"
                    activeClassName="active"
                    className="nav-links"
                    onClick={handleClick}
                  >
                    Country Corner
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/create-poll"
                    activeClassName="active"
                    className="nav-links"
                    onClick={handleClick}
                  >
                    Poll your Pictures
                  </NavLink>
                </li>
                <li className="nav-item d-none d-lg-block">
                  <Dropdown show={dropdownOpen} onToggle={() => setDropdownOpen(!dropdownOpen)}>
                    <Dropdown.Toggle className="text-white  fs-13 " variant="transparent" id="dropdown-basic">
                      <img src={userDetail?.data?.imagePath == "" ? dummyProfile : userDetail?.data?.imagePath} className='rounded-circle' alt="" width={40} height={40} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <div className="d-flex gap-2 align-items-center">
                          <img src={userDetail?.data?.imagePath == "" ? dummyProfile : userDetail?.data?.imagePath} className='rounded-circle' alt="" width={40} height={40} />
                          <div>
                            <p className="fs-13 fw-bold text-start lh-1">{userDetail?.data?.name}</p>
                            <p className="fs-13  text-start lh-1">{userDetail?.data?.email}</p>
                          </div>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item
                        // onClick={() => setShowLogin(true)}
                        onClick={() => {
                          setEditData(userDetail);
                          editUserHandler();
                        }}
                        className="lh-initial">Profile</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item onClick={handleLogout} className="lh-initial">Logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </>
            ) : (
              <>

                <li className="nav-item">
                  <NavLink
                    exact
                    to="/"
                    activeClassName="active"
                    className="nav-links"
                    onClick={handleClick}
                  >
                    Sign In
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/signUp"
                    activeClassName="active"
                    className="nav-links signUp"
                    onClick={handleClick}
                  >
                    Sign Up
                  </NavLink>
                </li>
              </>
            )}
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
          <Dropdown show={dropdownOpen} onToggle={() => setDropdownOpen(!dropdownOpen)} className="me-4 me-md-1 pe-3 d-lg-none d-block">
            <Dropdown.Toggle className="text-white  fs-13 " variant="transparent" id="dropdown-basic">
              <img src={userDetail?.data?.imagePath == "" ? dummyProfile : userDetail?.data?.imagePath} className='rounded-circle' alt="" width={40} height={40} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>
                <div className="d-flex gap-2 align-items-center">
                  <img src={userDetail?.data?.imagePath == "" ? dummyProfile : userDetail?.data?.imagePath} className='rounded-circle' alt="" width={40} height={40} />
                  <div>
                    <p className="fs-13 fw-bold text-start lh-1">{userDetail?.data?.name}</p>
                    <p className="fs-13  text-start lh-1">{userDetail?.data?.email}</p>
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                // onClick={() => setShowLogin(true)}
                onClick={() => {
                  setEditData(userDetail);
                  editUserHandler();
                }}
                className="lh-initial">Profile</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={handleLogout} className="lh-initial">Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
