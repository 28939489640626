import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { usePostAnalysisQuery } from '../../../services/PollApi';
import { useEffect } from 'react';

import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';


const PostAnalysisModal = ({ show, close, data, handleClose }) => {


    const { data: postAnalysisData,
        isError: postAnalysisIsError,
        error: postAnalysisError,
        isSuccess: postAnalysisIsSuccess,
        isLoading: postAnalysisIsLoading,
        refetch: postAnalysisRefetch } = usePostAnalysisQuery(data);

    console.log("postAnalysisData", postAnalysisData);

    useEffect(() => {
        // Trigger refetch when the component mounts or whenever 'data' changes
        if (data) {
            postAnalysisRefetch();
        }
    }, [data]);

    const chartData = [
        { name: 'Like By Male', value:  Math.round(postAnalysisData?.data?.likeByMale || 0), },
        { name: 'Like By Female', value: Math.round(postAnalysisData?.data?.likeByFemale || 0) },
        { name: 'Unknown Gender', value: Math.round(postAnalysisData?.data?.likeByUnknownGender || 0) },
    ];

    const ageData = [
        {
            name: 'Less Than Twenty',
            value: Math.round(postAnalysisData?.data?.lessThenTwenty || 0)
        },
        {
            name: 'Twenty to Thirty',
            value: Math.round(postAnalysisData?.data?.twentyToThirty || 0)
        },
        {
            name: 'Thirty to Forty',
            value: Math.round(postAnalysisData?.data?.thirtyToFourty || 0)
        },
        {
            name: 'Forty and Above',
            value: Math.round(postAnalysisData?.data?.fourtyAbove || 0)
        }
    ];
    return (
        <Modal show={show}
            dialogClassName='modal-dialog modal-lg modal-dialog-centered'
            centered
            onHide={() => {
                handleClose();
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title>Analysis</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-6">
                        <div>
                            <h5>Like Data</h5>
                            <PieChart width={400} height={400}>
                                <Pie
                                    data={chartData}
                                    dataKey="value"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={80}
                                    fill="#8884d8"
                                    label
                                >
                                    <Cell fill="#36A2EB" />
                                    <Cell fill="#FF6384" />
                                    <Cell fill="#CCCCCC" />
                                </Pie>
                                <Tooltip />
                                <Legend verticalAlign="top"
                                    height={36}
                                    layout="horizontal"
                                    align="center" />
                            </PieChart>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div>
                            <h5>Age Data</h5>
                            <PieChart width={400} height={400}>
                                <Pie
                                    data={ageData}
                                    dataKey='value'
                                    nameKey='name'
                                    cx='50%'
                                    cy='50%'
                                    outerRadius={80}
                                    innerRadius={40}
                                    fill='#8884d8'
                                    label
                                >
                                    <Cell fill='#36A2EB' />
                                    <Cell fill='#FF6384' />
                                    <Cell fill='#FF9F40' />
                                    <Cell fill='#FFCD56' />
                                </Pie>
                                <Tooltip />
                                <Legend
                                    verticalAlign='top'
                                    height={36}
                                    layout='horizontal'
                                    align='center'
                                />
                            </PieChart>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { handleClose() }}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default PostAnalysisModal